<template>
    <section class="invoice-preview-wrapper">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="invoiceData === undefined">
            <h4 class="alert-heading">
                Error fetching invoice data
            </h4>
            <div class="alert-body">
                No invoice found with this invoice id. Check
                <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
                    Invoice List
                </b-link>
                for other invoices.
            </div>
        </b-alert>

        <b-row v-if="invoiceData" class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="9" md="8">
                <b-card no-body class=" invoice-preview-card">
                    <!-- Header -->
                    <b-card-body style="background-color:#133842; color: white" class="invoice-padding pb-0">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <quote-logo :invoiceLogo="settingURL + 'invoice_logo.png'" />
                                </div>

                                <p class="card-text mb-25">{{ $func.getName() }}, {{ $func.getStreet() }} <br /></p>
                                <p class="card-text mb-25">{{ $func.getTown() }}, {{ $func.getPostCode() }}, {{ $func.getCountry() }}</p>
                                <p class="card-text mb-0">
                                    {{ $func.getPhone() }}
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <h4 style="background-color:#133842; color: white" class="invoice-title">
                                    Invoice
                                    <span class="invoice-number">#{{ invoiceData.id }}</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Date Issued:
                                    </p>
                                    <p class="invoice-date">
                                        {{ invoiceData.date }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Due Date:
                                    </p>
                                    <p class="invoice-date">
                                        {{ invoiceData.dueDate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body v-if="invoiceData.customerId" class="invoice-padding pt-0">
                        <b-row class="invoice-spacing">
                            <!-- Col: Invoice To -->
                            <b-col cols="12" xl="6" class="p-0">
                                <h5 class="mb-2">
                                    <strong> Invoice To:</strong>
                                </h5>
                                <h6 class="mb-25">
                                    {{ invoiceData.get_customer.name }}
                                </h6>

                                <p class="card-text mb-25">
                                    {{ invoiceData.get_customer.address }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.get_customer.email }}
                                </p>
                            </b-col>

                            <!-- Col: Payment Details -->
                            <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                                <!-- <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold"> $ {{ $func.formatPrice(invoiceData.total) }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>{{ invoiceData.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>{{ invoiceData.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ invoiceData.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>{{ invoiceData.swiftCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Invoice Description: Table -->
                    <b-table
                        responsive
                        :items="invoiceData.items"
                        :fields="[
                            { key: 'description', thStyle: 'background-color: #133842;color:white;', thClass: 'text-center' },
                            { key: 'po_number', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'quantity', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'cost', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'subtotal', thStyle: 'background-color: #133842;color:white', class: 'text-center' },

                            { key: 'vat', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'total', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                        ]"
                    >
                        <template #cell(po_number)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.item.poNumber }}
                            </b-card-text>
                        </template>
                        <template #cell(description)="data">
                            <b-card-text class="ml-4 font-weight-bold mb-25">
                                {{ data.item.itemTitle }}
                            </b-card-text>
                            <b-card-text class=" ml-4 text-nowrap">
                                <small> {{ data.item.description }}</small>
                            </b-card-text>
                        </template>

                        <template #cell(quantity)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.item.qty }}
                            </b-card-text>
                        </template>

                        <template #cell(vat)="data">
                            <b-card-text class="font-weight-bold mb-25 text-center"> {{ data.item.vat }}% </b-card-text>
                        </template>

                        <template #cell(cost)="data">
                            <b-card-text class="font-weight-bold mb-25">{{ $func.formatPrice(data.item.cost, invoiceData.currency) }} </b-card-text>
                        </template>

                        <template #cell(subtotal)="data">
                            <b-card-text class="font-weight-bold mb-25">{{ $func.formatPrice(data.item.subtotal, invoiceData.currency) }} </b-card-text>
                        </template>

                        <template #cell(total)="data">
                            <b-card-text class="font-weight-bold mb-25">{{ $func.formatPrice(data.item.total, invoiceData.currency) }} </b-card-text>
                        </template>
                    </b-table>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0">
                        <b-row>
                            <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2"> </b-col>
                            <!-- Col: Total -->
                            <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                <div class="invoice-total-wrapper">
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Subtotal:
                                        </p>
                                        <p class="invoice-total-amount">{{ $func.formatPrice(invoiceData.subtotal, invoiceData.currency) }}</p>
                                    </div>

                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            VAT:
                                        </p>
                                        <p class="invoice-total-amount">{{ $func.formatPrice(invoiceData.tax, invoiceData.currency) }}</p>
                                    </div>
                                    <hr class="my-50" />
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Total:
                                        </p>
                                        <p class="invoice-total-amount">{{ $func.formatPrice(invoiceData.total, invoiceData.currency) }}</p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Note -->
                    <b-card-body v-if="invoiceData.note" class="invoice-padding pt-0">
                        <span class="font-weight-bold">Note: </span>
                        <span>{{ invoiceData.note }}</span>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                <b-card>
                    <b-button v-show="$Can('invoice_approve')" v-if="invoiceData.status == 'A' && invoiceData.archive != 'yes'" @click="approveInvoice" variant="success" class="mb-75" block>
                        Approve
                    </b-button>

                    <!-- Button: Send Invoice -->
                    <b-button v-show="$Can('invoice_send')" @click="sendInvoice" variant="primary" class="mb-75" block>
                        Send Invoice
                    </b-button>
                    <!-- Button: DOwnload -->
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" @click="printInvoices" variant="outline-secondary" class="mb-75" block>
                        Download
                    </b-button>

                    <!-- Button: Print -->
                    <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
            @click="printInvoice">
            Print
          </b-button> -->

                    <!-- Button: Edit -->
                    <b-button
                        v-show="$Can('invoice_edit')"
                        v-if="invoiceData.status != 'B' && invoiceData.archive != 'yes'"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        :to="{ name: 'invoice-edit', params: { id: $route.params.id } }"
                    >
                        Edit
                    </b-button>

                    <!-- Button: Add Payment -->
                    <!-- <b-button v-b-toggle.sidebar-invoice-add-payment v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
            class="mb-75" block>
            Add Payment
          </b-button> -->
                </b-card>

                <b-card v-show="$Can('invoice_timeline')" v-if="invoiceData.timeline ? invoiceData.timeline.length : 0 > 0" style="overflow-y:scroll; height:500px">
                    <app-timeline class="mt-2">
                        <app-timeline-item v-for="item in invoiceData.timeline" :key="item.id" :variant="$func.varyant(item.status)" :icon="$func.varicon(item.status)">
                            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                <h6>{{ item.title }}</h6>
                                <small class="text-muted">{{ $func.dateFormatTimeline(item.created_at) }}</small>
                            </div>
                            <small v-if="item.userId != 0">{{ "By " + item.userNameSurname }}</small>
                            <small v-else>{{ item.userNameSurname }}</small>
                        </app-timeline-item>
                    </app-timeline>
                </b-card>
            </b-col>
        </b-row>

        <invoice-sidebar-send-invoice />
        <invoice-sidebar-add-payment />
    </section>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle } from "bootstrap-vue";
import QuoteLogo from "@core/layouts/components/QuoteLogo.vue";
import Ripple from "vue-ripple-directive";
import invoiceStoreModule from "../invoiceStoreModule";
import InvoiceSidebarSendInvoice from "../InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "../InvoiceSidebarAddPayment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,

        QuoteLogo,
        InvoiceSidebarAddPayment,
        InvoiceSidebarSendInvoice,

        AppTimeline,
        AppTimelineItem,
    },
    setup() {
        const invoiceData = ref(null);
        const paymentDetails = ref({});

        const INVOICE_STORE_MODULE_NAME = "invoice";

        // Register module
        if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        });

        const printInvoice = () => {
            window.print();
        };

        const download = false;
        const formShow = false;

        return {
            settingURL: store.state.app.settingURL,
            invoiceData,
            paymentDetails,
            printInvoice,
            download,
            formShow,
        };
    },

    methods: {
        fetchInvoice() {
            store
                .dispatch("invoice/fetchInvoice", { id: router.currentRoute.params.id })
                .then((response) => {
                    if (response.status == 200) {
                        this.invoiceData = response.data;
                    } else {
                        router.push({ name: "error-404" });
                    }
                })
                .catch((error) => {
                    router.push({ name: "error-404" });
                });
        },

        approveInvoice() {
            this.formShow = true;
            if (this.invoiceData.items.length > 0) {
                var info = { id: this.invoiceData.id, status: "B" };
                store
                    .dispatch("invoice/approveInvoice", info)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Successful",
                                text: "✔️ Invoice has been approved",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                        this.invoiceData.status = "B";
                    })
                    .catch((e) => {
                        this.$swal({
                            title: "Error",
                            text: "Process Failed. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                    });
            } else {
                this.$swal({
                    title: "Error",
                    text: "Process Failed. Please try again or contact support",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });

                this.formShow = false;
            }
            this.fetchInvoice();
        },

        printInvoices() {
            this.download = true;
            this.formShow = true;
            var val = this.invoiceData.id;
            store.dispatch("invoice/printInvoice", val).then((response) => {
                if (response.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Invoice_" + Number(val) + ".pdf");
                    document.body.appendChild(link);
                    link.click();
                    this.fetchInvoice();
                    this.formShow = false;

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successful",
                            text: "✔️ Sale Order is created!",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                        },
                    });
                    this.download = false;
                }
            });
        },

        sendInvoice() {
            this.formShow = true;
            var val = this.invoiceData.id;
            store.dispatch("invoice/sendInvoice", val).then((response) => {
                if (response.status == 200) {
                    this.fetchInvoice();
                    this.formShow = false;

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successful",
                            text: "✔️ Invoice sent!",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                        },
                    });
                }
            });
        },
    },
    created() {
        this.fetchInvoice();
    },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
    nav {
        display: none;
    }

    // Global Styles
    body {
        background-color: transparent !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    .theadClass {
        background-color: #133842 !important;
        color: white !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        > .col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>
