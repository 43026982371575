import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    customerDropInvoice() {
      return new Promise((resolve, reject) => {
        axios
          .get('customerDropInvoice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('fetchInvoices', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchArchiveInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('fetchArchiveInvoices', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    lastWeek(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('lastWeekEvents', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`showInvoice/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    saveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('saveInvoice', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`updateInvoice`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    approveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`approveInvoice`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    archiveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`archiveInvoice`, invoiceData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    printInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('printInvoice', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    sendInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('sendInvoice', { id: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
